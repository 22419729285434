<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室基础管理</h2>
                <div class="RightDiv">
                    <el-form :model="searchForm" class="searForm">
                        <div class="table-title">
                            <div>
                                <!-- <el-input class="search" v-model="searchForm.nameLike" placeholder="科室名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                                </el-input> -->
                            </div>
                        </div>
                    </el-form>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="totalNumberOfPeople" label="科室总人数" align="center">
                    </el-table-column>
                    <el-table-column prop="totalNumberOfBeds" label="科室总床位数" align="center">
                    </el-table-column>
                    <el-table-column prop="tablesNum" label="诊桌数" align="center">
                    </el-table-column>
                    <el-table-column prop="housingArea" label="房屋面积（㎡）" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template style="display: flex" slot-scope="scope">
                            <!-- <el-button size="mini" type="primary" @click="hoddepList(scope.row)"><i class="el-icon-tickets"></i></el-button> -->
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加学科弹出框 -->
        <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="1250px" top="1vh" :before-close="AdddialogVisibleClose">
            <div class="depClass">科室名称：{{depShowTex}}</div>
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" :inline="true" label-width="135px">
                <el-form-item label="主任医师人数：" prop="numchiphy">
                    <el-input type="number" v-model="AddDateModel.numchiphy" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="主治医师人数：" prop="numattdoc">
                    <el-input type="number" v-model="AddDateModel.numattdoc" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="医师人数：" prop="numdoc">
                    <el-input type="number" v-model="AddDateModel.numdoc" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="护师人数：" prop="numnurs">
                    <el-input type="number" v-model="AddDateModel.numnurs" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="护士人数：" prop="numofnu">
                    <el-input type="number" v-model="AddDateModel.numofnu" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>

                <el-form-item label="诊桌数：" prop="numtabs">
                    <el-input type="number" v-model="AddDateModel.numtabs" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>

                <el-form-item label="房屋面积(㎡)：" prop="houarea">
                    <el-input type="number" v-model="AddDateModel.houarea"></el-input>
                </el-form-item>

                <el-form-item label="普通床位数量：" prop="numordbeds">
                    <el-input type="number" v-model="AddDateModel.numordbeds" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="双人间床位数量：" prop="numdoubeds">
                    <el-input type="number" v-model="AddDateModel.numdoubeds" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="单人间床位数量：" prop="numsinbeds">
                    <el-input type="number" v-model="AddDateModel.numsinbeds" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="reamrk">
                    <el-input v-model="AddDateModel.reamrk" type="textarea"></el-input>
                </el-form-item>

                <div v-for="(domain, index) in AddDateModel.domains" :key="index">

                    <el-form-item label="选择医疗设备：" :key="domain.key1" :prop="'domains.' + index + '.priceType'" :rules="{
                      required: true, message: '医疗设备不能为空', trigger: 'change',
                    }">
                        <el-select v-model="domain.priceType" placeholder="选择医疗设备" clearable @change="typeChange(domain.priceType)">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="设备名称：" :key="domain.key1" :prop="'domains.' + index + '.name'" :rules="{
                      required: true, message: '设备名称不能为空', trigger: 'blur',
                    }">
                        <el-input v-model="domain.name" size="small"></el-input>
                    </el-form-item>

                    <el-form-item label="设备型号：" :key="domain.key1" :prop="'domains.' + index + '.model'" :rules="{
                      required: true, message: '设备型号不能为空', trigger: 'blur',
                    }">
                        <el-input v-model="domain.model" size="small"></el-input>
                    </el-form-item>

                    <el-form-item label="设备数量：" :key="domain.key1" :prop="'domains.' + index + '.eNum'" :rules="{
                      required: true, message: '设备数量不能为空', trigger: 'blur',
                    }">
                        <el-input v-model="domain.eNum" type="number" size="small"></el-input>
                    </el-form-item>

                    <el-form-item label="备注：" :key="domain.key1" :prop="'domains.' + index + '.remark'">
                        <el-input v-model="domain.remark" type="textarea" size="small"></el-input>
                    </el-form-item>

                </div>
                <!-- <el-form-item label="医疗设备：" prop="meequi">
                    <el-select v-model="AddDateModel.meequi" placeholder="选择医疗设备" clearable @change="typeChange(AddDateModel.meequi)">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备名称：" prop="equname">
                    <el-input v-model="AddDateModel.equname"></el-input>
                </el-form-item>
                <el-form-item label="设备型号：" prop="equemodel">
                    <el-input v-model="AddDateModel.equemodel"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="设备数量：" prop="numeque">
                    <el-input v-model="AddDateModel.numeque"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="text">
                    <el-input v-model="AddDateModel.text"></el-input>
                </el-form-item> -->

                <!--                 
 <el-form-item v-for="(meequiItem, index) in AddDateModel.hosDepartmentMedicalEquipmentList.meequi" :key="meequiItem.key" :prop="'meequi.' + index + '.value'" label="医疗设备：" :rules="AddDateRules.hosDepartmentMedicalEquipmentList.meequi">
                    <el-select v-model="meequiItem.value" placeholder="选择医疗设备" clearable @change="typeChange(meequiItem.value)">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item v-for="(equnameItem, index) in AddDateModel.hosDepartmentMedicalEquipmentList.equname" :key="equnameItem.key" :prop="'equname.' + index + '.value'" label="设备名称：" :rules="AddDateRules.hosDepartmentMedicalEquipmentList.equname">
                    <el-input v-model="equnameItem.value"></el-input>
                </el-form-item>

                <el-form-item v-for="(equemodelItem, index) in AddDateModel.hosDepartmentMedicalEquipmentList.equemodel" :key="equemodelItem.key" :prop="'equemodel.' + index + '.value'" label="设备型号：" :rules="AddDateRules.hosDepartmentMedicalEquipmentList.equemodel">
                    <el-input v-model="equemodelItem.value"></el-input>
                </el-form-item>

                <el-form-item v-for="(numequeItem, index) in AddDateModel.hosDepartmentMedicalEquipmentList.numeque" :key="numequeItem.key" :prop="'numeque.' + index + '.value'" label="设备数量：" :rules="AddDateRules.hosDepartmentMedicalEquipmentList.numeque">
                    <el-input v-model="numequeItem.value"></el-input>
                </el-form-item>

                <el-form-item v-for="(nameItem, index) in AddDateModel.hosDepartmentMedicalEquipmentList.text" :key="nameItem.key" :prop="'text.' + index + '.value'" label="备注：" :rules="AddDateRules.hosDepartmentMedicalEquipmentList.text">
                    <el-input v-model="nameItem.value"></el-input>
                </el-form-item> -->

                <el-form-item class="depar-dia btnCen">
                    <el-button class="editBtn" v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">{{btnText}}</el-button>
                    <el-button class="editBtn" v-else type="info" :loading="addDepClickKing"></el-button>
                    <el-button type="primary" @click="addparman">新增设备</el-button>
                    <el-button type="danger" @click="deleteparman">删除设备</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 查看详情 -->
        <!-- <el-dialog title="科室基础数据" :visible.sync="listdialogVisible" width="100%" :before-close="listdialogVisibleClose">
            <div class="rightBtn">
                <el-button type="primary" @click="depaddKeshi">添加科室基础信息</el-button>
            </div>
            <el-table :data="hoddepListtableData" border center>
                <el-table-column type="index" label="序号" width="55" align="center">
                </el-table-column>
                <el-table-column prop="departmentName" label="科室名称" align="center">
                </el-table-column>
                <el-table-column prop="totalNumberOfPeople" label="科室总人数" width="100" align="center">
                </el-table-column>
                <el-table-column prop="totalNumberOfBeds" label="科室总床位数" width="110" align="center">
                </el-table-column>
                <el-table-column prop="chiefPhysiciansNum" label="主任医师人数" width="120" align="center">
                </el-table-column>
                <el-table-column prop="attendingPhysiciansNum" label="主治医师人数" width="120" align="center">
                </el-table-column>
                <el-table-column prop="doctorsNum" label="医师人数" align="center">
                </el-table-column>

                <el-table-column prop="nursingTeachersNum" label="护师人数" align="center">
                </el-table-column>
                <el-table-column prop="nursesNum" label="护士人数" align="center">
                </el-table-column>
                <el-table-column prop="tablesNum" label="诊桌数" align="center">
                </el-table-column>
                <el-table-column prop="housingArea" label="房屋面积（㎡）" width="120" align="center">
                </el-table-column>
                <el-table-column prop="ordinaryBedsNum" label="普通床位数量" width="110" align="center">
                </el-table-column>
                <el-table-column prop="doubleBedNum" label="双人间床位数量" width="120" align="center">
                </el-table-column>
                <el-table-column prop="singleBedNum" label="单人间床位数量" width="120" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="center">
                </el-table-column>
                <el-table-column prop="deleteFlag" label="状态" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="120" fixed="right">
                    <template style="display: flex" slot-scope="scope">
                        <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="handleCurrentChangeHos" :current-page="hospageNum" :page-size="hospageSize" layout="prev, pager, next" :total="hodtotal">
            </el-pagination>
        </el-dialog> -->
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        return {
            listdialogVisible: false,
            equiShow: false,
            btnText: "添加",
            addName: "添加科室基础信息",
            options: [
                {
                    value: 1,
                    label: "百万以上",
                },
                {
                    value: 2,
                    label: "百万以下",
                },
            ],
            // 添加学科的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 查询用的业务名称
            searchForm: {
                nameLike: "",
            },
            AddDateModel: {
                numchiphy: "",
                numattdoc: "",
                numdoc: "",
                numnurs: "",
                numofnu: "",
                numtabs: "",
                houarea: "",
                numordbeds: "",
                numdoubeds: "",
                numsinbeds: "",
                reamrk: "",
                domains: [
                    {
                        name: "",
                        model: "",
                        remark: "",
                        eNum: "",
                        priceType: "",
                    },
                ],
            },
            AddDateRules: {
                numchiphy: [
                    {
                        required: true,
                        message: "请填写主任医师人数",
                        trigger: "blur",
                    },
                ],
                numattdoc: [
                    {
                        required: true,
                        message: "请填写主治医师人数",
                        trigger: "blur",
                    },
                ],
                numdoc: [
                    {
                        required: true,
                        message: "请填写医师人数",
                        trigger: "blur",
                    },
                ],
                numnurs: [
                    {
                        required: true,
                        message: "请填写护师人数",
                        trigger: "blur",
                    },
                ],
                numofnu: [
                    {
                        required: true,
                        message: "请填写护士人数",
                        trigger: "blur",
                    },
                ],
                numtabs: [
                    {
                        required: true,
                        message: "请填写诊桌数",
                        trigger: "blur",
                    },
                ],
                houarea: [
                    {
                        required: true,
                        message: "请填写房屋面积(㎡)",
                        trigger: "blur",
                    },
                ],
                numordbeds: [
                    {
                        required: true,
                        message: "请填写普通床位数量",
                        trigger: "blur",
                    },
                ],
                numdoubeds: [
                    {
                        required: true,
                        message: "请填写双人间床位数量",
                        trigger: "blur",
                    },
                ],
                numsinbeds: [
                    {
                        required: true,
                        message: "请填写单人间床位数量",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请填写设备名称",
                        trigger: "blur",
                    },
                ],
                model: [
                    {
                        required: true,
                        message: "请填写设备型号",
                        trigger: "blur",
                    },
                ],
                eNum: [
                    {
                        required: true,
                        message: "请填写设备数量",
                        trigger: "blur",
                    },
                ],
                priceType: [
                    {
                        required: true,
                        message: "请选择医疗设备",
                        trigger: "change",
                    },
                ],
            },
            // 点击添加学科弹出框
            AdddialogVisible: false,
            // 学科管理的分页
            // 当前页数
            pageNum: 1,
            hospageNum: 1,
            // 每页显示条数
            pageSize: 10,
            hospageSize: 10,
            // 总数
            total: 1,
            hodtotal: 1,
            // 表格数据
            tableData: [],
            hoddepListtableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 学科用户点击修改的id
            userId: "",
            keshiid: "",
            // 学科id
            keshiUserid: "",
            depShowTex: "",
        };
    },
    created() {
        this.getDepartmentList();
    },
    methods: {
        typeChange(val) {
            this.AddDateModel.domains.priceType = val;
        },
        // 搜索框
        searchList() {
            this.getDepartmentList();
        },
        // 学科管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.getDepartmentList();
        },
        handleCurrentChangeHos(val) {
            this.hospageNum = val;
            this.hoddepListTable();
        },
        // 点击添加学科
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        hoddepList(row) {
            this.departmentId = row.id;
            this.hoddepListTable();
        },
        async hoddepListTable() {
            let data = {
                deleteFlag: "0",
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                departmentId: this.departmentId, //所属科室id
            };
            let { data: res } = await this.$axios.hoddepList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // this.listdialogVisible = true;
                this.hoddepListtableData = res.rows;
                this.hodtotal = res.total;
            }
        },
        listdialogVisibleClose() {
            this.listdialogVisible = false;
            this.AddDateModel.domains = [
                {
                    name: "",
                    model: "",
                    remark: "",
                    eNum: "",
                    priceType: "",
                },
            ];
        },
        // 点击添加学科的时候取消正则
        AdddialogVisibleClose() {
            this.addName = "添加科室基础信息";
            this.btnText = "添加";
            this.depShowTex = "";
            this.AdddialogVisible = false;
            this.$refs.AddDateRef.resetFields();
            this.equiShow = false;
            this.hoddepListTable();
        },
        parmen() {
            if (this.btnText == "添加") {
                this.addKeshi();
            } else if (this.btnText == "修改") {
                this.editdialogKeshi();
            }
        },
        deleteparman() {
            if (this.AddDateModel.domains.length > 1) {
                this.AddDateModel.domains.splice(-1, 1);
            } else {
                this.$message({
                    message: "已经是最后一项了，不能删除了",
                    type: "error",
                });
            }
        },
        addparman() {
            this.AddDateModel.domains.push({
                name: "",
                model: "",
                remark: "",
                eNum: "",
                priceType: "",
            });
        },
        // 点击操作添加学科的添加
        addKeshi() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = {
                        departmentId: this.departmentId, //所属科室id
                        chiefPhysiciansNum: this.AddDateModel.numchiphy, //主任医师人数
                        attendingPhysiciansNum: this.AddDateModel.numattdoc, //主治医师人数
                        doctorsNum: this.AddDateModel.numdoc, //医师人数
                        nursingTeachersNum: this.AddDateModel.numnurs, //护师人数
                        nursesNum: this.AddDateModel.numofnu, //护士人数
                        tablesNum: this.AddDateModel.numtabs, //诊桌数
                        housingArea: this.AddDateModel.houarea, //房屋面积（㎡）
                        ordinaryBedsNum: this.AddDateModel.numordbeds, //普通床位数量
                        doubleBedNum: this.AddDateModel.numdoubeds, //双人间床位数量
                        singleBedNum: this.AddDateModel.numsinbeds, //单人间床位数量
                        remark: this.AddDateModel.reamrk, //备注
                        hosDepartmentMedicalEquipmentList:
                            this.AddDateModel.domains, //医疗设备
                    };
                    let res = await this.$axios.hosdepAdd(data);
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.data.code == 401) {
                        this.$router.push("/login");
                    } else if (res.status == 200) {
                        this.AdddialogVisibleClose();
                        this.hoddepListTable();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        // 点击操作修改学科的添加
        editKeshi(row) {
            // console.log(row);
            // this.departmentId = row.id;
            this.depShowTex = row.departmentName;
            this.addName = "修改科室基础信息";
            this.btnText = "修改";
            this.AdddialogVisible = true;
            this.hoddepFind(row.id);
        },
        async hoddepFind(id) {
            let data = {
                id: id,
            };
            let res = await this.$axios.hoddepFind(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                // console.log(res.data.data);
                this.departmentId = res.data.data.departmentId;
                this.edId = res.data.data.id;
                this.AddDateModel.numchiphy = res.data.data.chiefPhysiciansNum;
                this.AddDateModel.numattdoc =
                    res.data.data.attendingPhysiciansNum;
                this.AddDateModel.numdoc = res.data.data.doctorsNum;
                this.AddDateModel.numnurs = res.data.data.nursingTeachersNum;
                this.AddDateModel.numofnu = res.data.data.nursesNum;
                this.AddDateModel.numtabs = res.data.data.tablesNum;
                this.AddDateModel.houarea = res.data.data.housingArea;
                this.AddDateModel.numordbeds = res.data.data.ordinaryBedsNum;
                this.AddDateModel.numdoubeds = res.data.data.doubleBedNum;
                this.AddDateModel.numsinbeds = res.data.data.singleBedNum;
                this.AddDateModel.reamrk = res.data.data.remark;
                this.AddDateModel.domains =
                    res.data.data.hosDepartmentMedicalEquipmentList;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 点击修改学科用户管理的添加
        editdialogKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = {
                    id: this.edId,
                    departmentId: this.departmentId, //所属科室id
                    chiefPhysiciansNum: this.AddDateModel.numchiphy + "", //主任医师人数
                    attendingPhysiciansNum: this.AddDateModel.numattdoc + "", //主治医师人数
                    doctorsNum: this.AddDateModel.numdoc + "", //医师人数
                    nursingTeachersNum: this.AddDateModel.numnurs + "", //护师人数
                    nursesNum: this.AddDateModel.numofnu + "", //护士人数
                    tablesNum: this.AddDateModel.numtabs + "", //诊桌数
                    housingArea: this.AddDateModel.houarea + "", //房屋面积（㎡）
                    ordinaryBedsNum: this.AddDateModel.numordbeds + "", //普通床位数量
                    doubleBedNum: this.AddDateModel.numdoubeds + "", //双人间床位数量
                    singleBedNum: this.AddDateModel.numsinbeds + "", //单人间床位数量
                    remark: this.AddDateModel.reamrk, //备注
                    hosDepartmentMedicalEquipmentList:
                        this.AddDateModel.domains, //医疗设备
                };
                this.addDepartment(data);
            });
        },
        // 添加或修改学科信息接口
        async addDepartment(data) {
            let res = await this.$axios.hosdepUpdate(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.getDepartmentList();
                this.AdddialogVisibleClose();
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 分页显示学科管理数据
        async getDepartmentList() {
            let data = {
                deleteFlag: "0",
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                departmentId: "",
                // nameLike: this.searchForm.nameLike, //查询用的 科室名称
            };
            let { data: res } = await this.$axios.hoddepList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-textarea__inner {
    width: 500px;
}

/deep/ .el-input__inner {
    width: 146px;
}

/deep/ .el-input--suffix .el-input__inner {
    width: 146px;
}

/deep/ .search.el-input--suffix .el-input__inner {
    width: 200px !important;
}

/deep/ .el-dialog__body {
    text-align: inherit;
    .btnCen {
        width: 100%;
        text-align: center;
    }
}

.depClass {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 25px;
}

.RightDiv {
    margin: 0 auto;
}
.searForm {
    float: right;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.depar-dia {
    width: 100%;
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.rightBtn {
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.editBtn {
    width: 150px;
}
</style>
